import {
  StyleParamType,
  createStylesParams,
  wixColorParam,
  ISiteTextPreset,
  IWixStyleFont,
  wixFontParam,
  createStylesParam,
} from '@wix/tpa-settings'
import {
  EventDetailsAlignment,
  EventDetailsImageRatio,
  HEADER_IMAGE_ALIGNMENT,
  IMAGE_SCALING,
  TICKET_PICKER_LAYOUT,
} from '@wix/wix-events-commons-statics'
import {Override} from '../../commons/types/utils'
import {PageComponentTextSettings} from './settingsParams'

type IBooleans = {
  shortDateLocationVisible: StyleParamType.Boolean
  descriptionVisible: StyleParamType.Boolean
  membersVisible: StyleParamType.Boolean
  membershipsVisible: StyleParamType.Boolean
  groupVisible: StyleParamType.Boolean
  aboutSectionVisible: StyleParamType.Boolean
  scheduleVisible: StyleParamType.Boolean
  mapVisible: StyleParamType.Boolean
  socialShareVisible: StyleParamType.Boolean
  readMoreButtonVisible: StyleParamType.Boolean
  headerRegistrationButtonVisible: StyleParamType.Boolean
  pageRegistrationButtonVisible: StyleParamType.Boolean
  ticketPickerCardsShadowVisible: StyleParamType.Boolean
  responsive: StyleParamType.Boolean
}

type INumbers = {
  headerLayout: StyleParamType.Number
  headerImageAlignment: StyleParamType.Number
  headerBorderWidth: StyleParamType.Number
  evTicketDividerSize: StyleParamType.Number
  rsvpButtonBorderWidth: StyleParamType.Number
  rsvpButtonBorderRadius: StyleParamType.Number
  rsvpButtonRoundedBorderRadius: StyleParamType.Number
  formButtonBorderWidth: StyleParamType.Number
  formButtonBorderRadius: StyleParamType.Number
  formButtonRoundedBorderRadius: StyleParamType.Number
  formSummaryBoxBorderSize: StyleParamType.Number
  imageOpacity: StyleParamType.Number
  buttonStyle: StyleParamType.Number
  imageRatio: StyleParamType.Number
  imageScaling: StyleParamType.Number
  headerAlignment: StyleParamType.Number
  contentAlignment: StyleParamType.Number
  formButtonStyle: StyleParamType.Number
  ticketPickerLayout: StyleParamType.Number
  ticketPickerListSpacing: StyleParamType.Number
  ticketPickerCardSpacing: StyleParamType.Number
  ticketPickerListHorizontalDividerWidth: StyleParamType.Number
  ticketPickerListVerticalDividerWidth: StyleParamType.Number
  ticketPickerListInputBorderRadius: StyleParamType.Number
  ticketPickerCardsInputBorderRadius: StyleParamType.Number
  ticketPickerCardsVerticalDividerWidth: StyleParamType.Number
  ticketPickerCardsBorderWidth: StyleParamType.Number
  ticketPickerCardsBorderRadius: StyleParamType.Number
  ticketPickerCardsShadowAngle: StyleParamType.Number
  ticketPickerCardsShadowDistance: StyleParamType.Number
  ticketPickerCardsShadowBlur: StyleParamType.Number
  mobileShortDateTimeSize: StyleParamType.Number
  mobileEventTitleSize: StyleParamType.Number
  mobileDescriptionSize: StyleParamType.Number
  mobileButtonTextSize: StyleParamType.Number
  mobileSubtitlesSize: StyleParamType.Number
  mobileTextSize: StyleParamType.Number
  mobileScheduleTimeSize: StyleParamType.Number
  mobileScheduleDurationSize: StyleParamType.Number
  mobileScheduleTitleSize: StyleParamType.Number
  mobileScheduleLocationSize: StyleParamType.Number
  mobileTicketsPickerTicketNameSize: StyleParamType.Number
  mobileTicketsPickerMainFontSize: StyleParamType.Number
  mobileTicketsPickerAdditionalFontSize: StyleParamType.Number
}

type IStyles = {
  shortDateLocationFont: StyleParamType.Font
  shortDateLocationColor: StyleParamType.Color
  descriptionFont: StyleParamType.Font
  descriptionColor: StyleParamType.Color
  titleFont: StyleParamType.Font
  titleColor: StyleParamType.Color
  subtitlesFont: StyleParamType.Font
  subtitlesColor: StyleParamType.Color
  textFont: StyleParamType.Font
  textColor: StyleParamType.Color
  evTicketNameFont: StyleParamType.Font
  evTicketNameColor: StyleParamType.Color
  evTicketAdditionalInfoColor: StyleParamType.Color
  ticketPickerListMainFont: StyleParamType.Font
  ticketPickerCardsMainFont: StyleParamType.Font
  ticketPickerListMainColor: StyleParamType.Color
  ticketPickerCardsMainColor: StyleParamType.Color
  ticketPickerListAdditionalFont: StyleParamType.Font
  ticketPickerCardsAdditionalFont: StyleParamType.Font
  ticketPickerListAdditionalColor: StyleParamType.Color
  ticketPickerCardsAdditionalColor: StyleParamType.Color
  ticketPickerCardsBackgroundColor: StyleParamType.Color
  ticketPickerCardsBorderColor: StyleParamType.Color
  ticketPickerCardsVerticalDividerColor: StyleParamType.Color
  ticketPickerCardsShadowColor: StyleParamType.Color
  ticketPickerListVerticalDividerColor: StyleParamType.Color
  ticketPickerListHorizontalDividerColor: StyleParamType.Color
  scheduleTimeFont: StyleParamType.Font
  scheduleTimeColor: StyleParamType.Color
  scheduleDurationFont: StyleParamType.Font
  scheduleDurationColor: StyleParamType.Color
  scheduleTitleFont: StyleParamType.Font
  scheduleTitleColor: StyleParamType.Color
  scheduleLocationFont: StyleParamType.Font
  scheduleLocationColor: StyleParamType.Color
  headerBackgroundColor: StyleParamType.Color
  evBackgroundColor: StyleParamType.Color
  headerBorderOpacityAndColor: StyleParamType.Color
  colorBehindImage: StyleParamType.Color
  rsvpHollowButtonColor: StyleParamType.Color
  rsvpButtonBorderColor: StyleParamType.Color
  secondRsvpHollowButtonColor: StyleParamType.Color
  secondRsvpButtonBorderColor: StyleParamType.Color
  rsvpHollowButtonFont: StyleParamType.Font
  rsvpButtonFont: StyleParamType.Font
  rsvpButtonColor: StyleParamType.Color
  rsvpButtonBackgroundColor: StyleParamType.Color
  secondRsvpButtonColor: StyleParamType.Color
  secondRsvpButtonBackgroundColor: StyleParamType.Color
  formSummaryTitleFont: StyleParamType.Font
  formSummaryBodyFont: StyleParamType.Font
  formSummaryColor: StyleParamType.Color
  formSummaryBoxBackgroundColor: StyleParamType.Color
  formSummaryBoxBorderColor: StyleParamType.Color
  formTitleFont: StyleParamType.Font
  formTitleColor: StyleParamType.Color
  formLabelsFont: StyleParamType.Font
  formLabelsColor: StyleParamType.Color
  formBackgroundColor: StyleParamType.Color
  formHollowButtonFont: StyleParamType.Font
  formHollowButtonColor: StyleParamType.Color
  formButtonBorderColor: StyleParamType.Color
  formButtonBackgroundColor: StyleParamType.Color
  formFullButtonColor: StyleParamType.Color
  formFullButtonFont: StyleParamType.Font
}

type IStylesParams = INumbers & IBooleans & IStyles

export type PageComponentSettings = Override<INumbers, number> &
  Override<IBooleans, boolean> & {texts: PageComponentTextSettings}

type ParamsMapper<Type, Val> = {
  [Property in keyof Type]: Val
}

export type DetailsStyleParams = {
  numbers: ParamsMapper<INumbers, number>
  booleans: ParamsMapper<IBooleans, boolean> & {responsive: boolean; __IS_RTL__: boolean}
  colors: IStyles
  fonts: IStyles
  strings: any
}

const getDefaultFont = (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) => {
  return ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})
    return font as IWixStyleFont
  }
}

const textColor = createStylesParam('textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
})
const textFont = createStylesParam('textFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const evTicketNameColor = createStylesParam('evTicketNameColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
})
const evTicketNameFont = createStylesParam('evTicketNameFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 20,
  }),
})

const mobileTicketsPickerTicketNameSize = createStylesParam('mobileTicketsPickerTicketNameSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 20,
})

export default createStylesParams<IStylesParams>({
  headerLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  headerImageAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => HEADER_IMAGE_ALIGNMENT.LEFT,
  },
  headerAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => EventDetailsAlignment.CENTER,
  },
  contentAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({isRTL}) => (isRTL ? EventDetailsAlignment.RIGHT : EventDetailsAlignment.LEFT),
  },
  buttonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => EventDetailsImageRatio['16:9'],
  },
  imageScaling: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_SCALING.CROP,
  },
  headerBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  evTicketDividerSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  rsvpButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  rsvpButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  rsvpButtonRoundedBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  formButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  formButtonRoundedBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  formSummaryBoxBorderSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  shortDateLocationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  shortDateLocationFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 18,
    }),
  },
  shortDateLocationVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 60,
    }),
  },
  descriptionVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  subtitlesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subtitlesFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 30,
    }),
  },
  textColor,
  textFont,
  evTicketNameColor,
  evTicketNameFont,
  ticketPickerCardsMainColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(evTicketNameColor),
  },
  ticketPickerCardsMainFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => ({
      ...getStyleParamValue(evTicketNameFont),
      size: 20,
    }),
  },
  ticketPickerListMainColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(evTicketNameColor),
  },
  ticketPickerListMainFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => ({
      ...getStyleParamValue(evTicketNameFont),
      size: 20,
    }),
  },
  ticketPickerCardsAdditionalColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(textColor),
  },
  ticketPickerCardsAdditionalFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => ({
      ...getStyleParamValue(textFont),
      size: 14,
    }),
  },
  ticketPickerListAdditionalColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(textColor),
  },
  ticketPickerListAdditionalFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => ({
      ...getStyleParamValue(textFont),
      size: 14,
    }),
  },
  evTicketAdditionalInfoColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  ticketPickerCardsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  ticketPickerCardsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  ticketPickerCardsVerticalDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  ticketPickerListVerticalDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  ticketPickerListHorizontalDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  ticketPickerCardsShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.12),
  },
  scheduleTimeColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleTimeFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  scheduleDurationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  scheduleDurationFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  scheduleTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  scheduleLocationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleLocationFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  headerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  evBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerBorderOpacityAndColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  imageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  colorBehindImage: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-7'),
  },
  rsvpHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  rsvpHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  rsvpButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  rsvpButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  rsvpButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  rsvpButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  secondRsvpButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  membersVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  aboutSectionVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  groupVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  membershipsVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  socialShareVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  mapVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  scheduleVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerRegistrationButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  pageRegistrationButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  ticketPickerCardsShadowVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  readMoreButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  formSummaryColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formSummaryTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 20,
    }),
  },
  formSummaryBodyFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-S', {
      size: 14,
    }),
  },
  formSummaryBoxBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formSummaryBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  formTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 30,
    }),
  },
  formLabelsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formLabelsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  formBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  formHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formFullButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  formFullButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formButtonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  formButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  ticketPickerLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => TICKET_PICKER_LAYOUT.LIST,
  },
  ticketPickerListSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  ticketPickerCardSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
  ticketPickerListVerticalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ticketPickerCardsVerticalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ticketPickerListHorizontalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ticketPickerCardsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ticketPickerCardsBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  ticketPickerCardsShadowAngle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 135,
  },
  ticketPickerCardsShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  ticketPickerCardsShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  },
  ticketPickerListInputBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  ticketPickerCardsInputBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  responsive: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  mobileShortDateTimeSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileEventTitleSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  mobileDescriptionSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileButtonTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobileSubtitlesSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  mobileTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleTimeSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleDurationSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleTitleSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleLocationSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileTicketsPickerTicketNameSize,
  mobileTicketsPickerMainFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(mobileTicketsPickerTicketNameSize) ?? 20,
  },
  mobileTicketsPickerAdditionalFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
})

export {customCssVars} from './custom-css-vars'
